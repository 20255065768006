<template>
  <div class="fixed-top bg-green-custom animation-duration-item-navbar"
    :class="{ 'box-width-1': isShowNavbar, 'box-width-2': !isShowNavbar }" style="height: 100% !important">
    <div class="px-2 d-grid text-white">
      <div class="d-flex align-items-center justify-content-between py-1 px-4"
        :class="{ 'item-navbar': !isShowNavbar }">
        <!-- <div class="bg-white d-flex align-items-center justify-content-center"
                    style="border-radius: 50%; width: 32px; height: 32px;" v-if="isShowNavbar">
                    <img src="@/assets/img/foto_profil.png" alt="" width="20">
                </div> -->
        <div class="w-100" v-if="!isShowNavbar">
          <img src="@/assets/icon/arrow-right.svg" class="animation-duration-toggle-navbar"
            :class="{ 'icon-right': isHovered }" style="cursor: pointer" @mouseover="isHovered = true"
            @mouseleave="isHovered = false" @click="toggleNavbar" />
        </div>
      </div>

      <div class="border-bottom font-custom-2 fw-bold fs-7 cursor-pointer" :class="{ 'item-navbar': !isShowNavbar }">
        <div class="px-2 d-grid gap-2 text-white position-relative">
          <ItemNavbar :title="nama_lengkap" ref-name="profile" :icon-url="profile_picture" width-icon="30"
            :is-show-navbar="isShowNavbar" is-profile="true" :nim="no_user" />
          <div class="pe-2 d-flex justify-content-end position-absolute end-0 h-100 align-items-center"
            v-if="isShowNavbar" @mouseover="isHovered = true" @mouseleave="isHovered = false" @click="toggleNavbar">
            <img src="@/assets/icon/arrow-left.svg" class="animation-duration-toggle-navbar"
              :class="{ 'icon-left': isHovered }" style="cursor: pointer" />
          </div>
        </div>
        <div>
          <ItemNavbar title="Notifikasi" ref-name="notifikasi" icon-url="icon/notifikasi.png" width-icon="15"
            :is-show-navbar="isShowNavbar" tittle-bold="false" is-notifikasi="true" @click="goPage('notifikasi')"
            v-if="tipe == 'pegawai'" />
        </div>

        <div>
          <ItemNavbar title="Keluar" ref-name="keluar" icon-url="icon/logout.png" width-icon="15"
            :is-show-navbar="isShowNavbar" tittle-bold="false" is-logout="true" @click="logout()" />
        </div>
        <div class="mt-3 ps-4 pt-2 pb-3 d-flex align-items-center gap-2" @click="goPage('home')">
          <div>
            <img src="@/assets/icon/home.svg" alt="" />
          </div>
          <div v-show="isShowNavbar" class="ps-1 pt-1">Home</div>
        </div>
      </div>
    </div>
    <div ref="scrollableDiv" class="pt-1 px-2 d-grid gap-2 text-white overflow-y-scroll mt-2"
      style="max-height: 60vh !important" @scroll="saveScrollPosition">
      <ItemNavbar title="Kelola Berita" ref-name="kelola_berita" icon-url="icon/kelola_berita.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('kelola_berita')" />
      <!-- ini jangan -->
      <!-- <ItemNavbar title="Berita" ref-name="berita" icon-url="icon/berita.png" width-icon="15"
                :is-show-navbar="isShowNavbar" v-if="showMenu('berita')" /> -->
      <!-- ini jangan -->
      <ItemNavbar title="Fakultas" ref-name="fakultas" icon-url="icon/fakultas.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('fakultas')" />
      <ItemNavbar title="Prodi" ref-name="prodi" icon-url="icon/prodi.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('prodi')" />
      <ItemNavbar title="Tahun Ajaran" ref-name="tahun_ajaran" icon-url="icon/tahun_ajaran.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('tahun_ajaran')" />
      <ItemNavbar title="Kurikulum" ref-name="kurikulum" icon-url="icon/kurikulum.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('kurikulum')" />
      <ItemNavbar title="Stambuk" ref-name="stambuk" icon-url="icon/stambuk.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('stambuk')" />
      <ItemNavbar title="Semester" ref-name="semester" icon-url="icon/semester.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('semester')" />
      <ItemNavbar title="Jenis Mata Kuliah Pilihan" ref-name="jenis_mata_kuliah_pilihan"
        icon-url="icon/jenis_mata_kuliah_pilihan.png" width-icon="15" :is-show-navbar="isShowNavbar"
        v-if="showMenu('jenis_mata_kuliah_pilihan')" />
      <ItemNavbar title="Mata Kuliah" ref-name="mata_kuliah" icon-url="icon/mata_kuliah.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('mata_kuliah')" />
      <ItemNavbar title="Waktu Kuliah" ref-name="waktu_kuliah" icon-url="icon/waktu_kuliah.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('waktu_kuliah')" />
      <ItemNavbar title="Ruangan" ref-name="ruangan" icon-url="icon/ruangan.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('ruangan')" />
      <ItemNavbar title="Kelas" ref-name="kelas" icon-url="icon/kelas.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('kelas')" />
      <ItemNavbar title="Pemetaan Kelas" ref-name="pemetaan_kelas" icon-url="icon/pemetaan_kelas.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('pemetaan_kelas')" />
      <ItemNavbar title="Jadwal Kuliah" ref-name="jadwal_kuliah" icon-url="icon/jadwal_kuliah.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('jadwal_kuliah')" />
      <ItemNavbar title="Jadwal Ujian" ref-name="jadwal_ujian" icon-url="icon/jadwal_ujian.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('jadwal_ujian')" />
      <ItemNavbar title="Jadwal Remedial" ref-name="jadwal_remedial" icon-url="icon/jadwal_remedial.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('jadwal_remedial')" />
      <ItemNavbar title="Jadwal Semester Pendek" ref-name="semester_pendek" icon-url="icon/jadwal_remedial.png"
        width-icon="15" :is-show-navbar="isShowNavbar" v-if="showMenu('semester_pendek')" />
      <ItemNavbar title="Jadwal Mengajar" ref-name="jadwal_mengajar" icon-url="icon/jadwal_mengajar.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="
          showMenu('jadwal_mengajar') && (tipe == 'pegawai' || tipe == 'admin' || tipe == 'dosen')
        " />
      <ItemNavbar title="Jadwal Mengawas" ref-name="jadwal_mengawas" icon-url="icon/jadwal_mengawas.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="tipe == 'dosen'" />
      <ItemNavbar title="Jadwal Mengawas Remedial" ref-name="jadwal_mengawas_remedial"
        icon-url="icon/jadwal_mengawas.png" width-icon="15" :is-show-navbar="isShowNavbar" v-if="tipe == 'dosen'" />
      <ItemNavbar title="Laporan" ref-name="laporan" icon-url="icon/laporan.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="(showMenu('laporan') && tipe == 'dosen') ||
          (showMenu('laporan') && tipe == 'pegawai') ||
          tipe == 'admin'
        " />
      <ItemNavbar title="Mahasiswa" ref-name="mahasiswa" icon-url="icon/mahasiswa.svg" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('mahasiswa')" />
      <ItemNavbar title="Score Mahasiswa" ref-name="score_mahasiswa" icon-url="icon/score_mahasiswa.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('score_mahasiswa')" />
      <ItemNavbar title="Penilaian" ref-name="penilaian" icon-url="icon/penilaian.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="(showMenu('penilaian') && tipe == 'dosen') ||
          (showMenu('penilaian') && tipe == 'pegawai') ||
          tipe == 'admin'
        " />
      <ItemNavbar title="Dosen Wali" ref-name="mahasiswa_wali" icon-url="icon/mahasiswa.svg" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('mahasiswa_wali') && tipe == 'dosen'" />

      <ItemNavbar title="KRS" ref-name="krs" icon-url="icon/krs.png" width-icon="15" :is-show-navbar="isShowNavbar"
        v-if="showMenu('krs')" />
      <ItemNavbar title=" Golongan" ref-name="golongan" icon-url="icon/golongan.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('golongan')" />
      <ItemNavbar title="Jabatan" ref-name="jabatan" icon-url="icon/jabatan.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('jabatan')" />
      <ItemNavbar title="Bidang" ref-name="bidang" icon-url="icon/bidang.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('bidang')" />
      <ItemNavbar title="Dosen" ref-name="dosen" icon-url="icon/dosen.svg" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('dosen')" />
      <ItemNavbar title="Penelitian Dosen" ref-name="penelitian_dosen" icon-url="icon/penelitian.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('penelitian_dosen')" />
      <ItemNavbar title="Pegawai" ref-name="pegawai_pegawai" icon-url="icon/pegawai.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('pegawai')" />
      <ItemNavbar title="Kalender Akademik" ref-name="kalender_akademik" icon-url="icon/kalender_akademik.png"
        width-icon="15" :is-show-navbar="isShowNavbar" v-if="showMenu('kalender_akademik')" />
      <ItemNavbar title="Tagihan" ref-name="tagihan" icon-url="icon/tagihan.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('tagihan')" />
      <ItemNavbar title="Kuesioner" ref-name="kuesioner" icon-url="icon/kuesioner.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('kuesioner')" />

      <!-- <ItemNavbar title="Isi Kuesioner" ref-name="isi_kuesioner" icon-url="icon/kuesioner.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('isi_kuesioner') && tipe == 'dosen'" /> -->
      <ItemNavbar title="polling" ref-name="polling" icon-url="icon/polling.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('polling')" />

      <ItemNavbar title="Admin" ref-name="admin" icon-url="icon/admin.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('admin')" />
      <ItemNavbar title="Menu Akses" ref-name="menu_akses" icon-url="icon/menu_akses.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="showMenu('menu_akses')" />
      <ItemNavbar title="Isi Kuesioner" ref-name="isi_kuesioner" icon-url="icon/penilaian.png" width-icon="15"
        :is-show-navbar="isShowNavbar" v-if="(showMenu('isi_kuesioner') && tipe == 'dosen')" />
      <ItemNavbar title="Pindah waktu kuliah" ref-name="pindah_waktu_kuliah" icon-url="icon/pindah_waktu_kuliah.png"
        width-icon="15" :is-show-navbar="isShowNavbar" v-if="showMenu('pindah_waktu_kuliah')" />
      <ItemNavbar title="Surat Aktif Kuliah" ref-name="surat_aktif_kuliah" icon-url="icon/surat_aktif_kuliah.png"
        width-icon="15" :is-show-navbar="isShowNavbar" v-if="showMenu('surat_aktif_kuliah')" />
      <ItemNavbar title="Surat Pengunduran Diri" ref-name="surat_pengunduran_diri"
        icon-url="icon/surat_pengunduran_diri.png" width-icon="15" :is-show-navbar="isShowNavbar"
        v-if="showMenu('surat_pengunduran_diri')" />
      <ItemNavbar title="Surat Pengajuan Cuti" ref-name="surat_pengajuan_cuti"
        icon-url="icon/surat_pengunduran_diri.png" width-icon="15" :is-show-navbar="isShowNavbar"
        v-if="showMenu('surat_pengajuan_cuti')" />
      <ItemNavbar title="Pergantian Kartu" ref-name="pergantian_kartu" icon-url="icon/pergantian_kartu.png"
        width-icon="15" :is-show-navbar="isShowNavbar" v-if="showMenu('pergantian_kartu')" />

      <!-- ini jangan -->
      <!-- <ItemNavbar title=" Mata Kuliah Umum" ref-name="mata_kuliah_umum" icon-url="icon/mata_kuliah_umum.png"
        width-icon="15" :is-show-navbar="isShowNavbar" v-if="showMenu('mata_kuliah_umum')" /> -->
      <!-- <ItemNavbar title="Jam Kuliah" ref-name="jam_kuliah" icon-url="icon/jam_kuliah.png" width-icon="15"
                :is-show-navbar="isShowNavbar" /> -->
      <!-- ini jangan -->
    </div>
  </div>
  <div class="fixed-bottom bg-blue-custom font-custom-3 d-none" style="height: 4vh">
    <div class="ps-5 d-flex align-items-center">
      <div class="ps-5 d-flex gap-5 fs-8">
        <a href="" style="color: white !important"> Terms & privacy </a>
        <a href="" style="color: white !important"> Accessibility </a>
        <a href="" style="color: white !important"> Emergency </a>
        <a href="" style="color: white !important"> Privacy </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.box-width-1 {
  width: 190px !important;
}

.box-width-2 {
  width: 82px !important;
}

.icon-left {
  transform: translateX(-2px);
}

.icon-right {
  transform: translateX(2px);
}

.animation-duration-toggle-navbar {
  transition: all 0.1s ease !important;
}

::-webkit-scrollbar {
  width: 0.8rem;
  border-radius: 0.5rem;
  background-color: rgba(63, 63, 63, 0.269);
  height: 0rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.333);
  border-radius: 0.5rem;
  width: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
  width: 0.3rem;
  background-color: rgba(255, 255, 255, 0.652);
}
</style>

<script>
import ItemNavbar from "@/components/navbarComponents/ItemNavbar.vue";
import {
  goPage,
  base_url,
  ShowLoading,
  AlertPopup,
  ErrorConnectionTimeOut,
  CloseLoading,
} from "@/assets/javascript/function";
import axios from "axios";
import Cookies from "js-cookie";

// import ItemNavbarExpand from "./navbarComponents/ItemNavbarExpand.vue";
export default {
  data() {
    return {
      //data
      url: "logout",

      //profile
      nama_lengkap: "",
      profile_picture: "",
      no_user: "",
      aksesMenu: "",

      //tampilan
      isHovered: false,
      isShowNavbar: false,

      //auth
      tipe: "",
    };
  },

  components: {
    ItemNavbar,
    // ItemNavbarExpand
  },
  emits: ["isShowNavbar"],
  methods: {
    goPage,
    showMenu(menu2) {
      if (this.tipe == "admin") {
        return true;
      } else {
        if (this.aksesMenu.includes(menu2)) {
          return true;
        } else {
          return false;
        }
      }
    },
    index() {
      this.tipe = localStorage.getItem("tipe_user");
      this.nama_lengkap = localStorage.getItem("nama_user");
      this.profile_picture = localStorage.getItem("profile_picture");
      this.aksesMenu = JSON.parse(localStorage.getItem("menu"));

      // let formData = new FormData();
      // if (process.env.NODE_ENV === "development") {
      //     this.api = base_url + "profile_detail";
      // } else {
      //     this.api = base_url + "profile_detail";
      // }
      // axios
      //     .post(this.api, formData, this.config)
      //     .then((response) => {
      //         if (response.data.status == true) {
      //             this.tipe = response.data.tipe;
      //             this.nama_lengkap = response.data.data
      //         }

      //         if (response.data.status == false) {
      //             // AlertPopup("error", "", response.data.message, 1500, false);
      //         }
      //     })
      //     .catch((error) => {
      //         ErrorConnectionTimeOut(error);
      //     });
    },
    logout() {
      const formData = new FormData();

      ShowLoading();
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url;
      } else {
        this.api = base_url + this.url;
      }

      this.token = Cookies.get("token");
      this.config = {
        headers: { Authorization: `Bearer ` + this.token },
        timeout: 0,
      };
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            Cookies.set("token", "");
            // AlertPopup("success", response.data.message, "", 1500, false);
            CloseLoading();
            this.goPage("");
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    index_notifikasi() {
      let formData = new FormData();

      formData.append("ditujukan", "pegawai");
      formData.append("limit", "100");
      formData.append("offset", "0");
      formData.append("query", "");
      formData.append("filter", "");

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "notifikasi_index";
      } else {
        this.api = base_url + "notifikasi_index";
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            if (response.data.data != undefined) {
              for (let i = 0; i < response.data.data[1].length; i++) {
                let judul = response.data.data[1][i].judul;
                if (judul == localStorage.getItem("lastReadNotif")) {
                  break;
                } else {
                  localStorage.setItem(
                    "notifSum",
                    parseInt(localStorage.getItem("notifSum")) + 1
                  );
                }
              }

              localStorage.setItem(
                "lastReadNotif",
                response.data.data[1][0].judul
              );
            }
            CloseLoading();
          }

          if (response.data.status == false) {
            // AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    check_version() {

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "check_version";
      } else {
        this.api = base_url + "check_version";
      }


      axios
        .post(this.api)
        .then((response) => {
          if (response.data.status == true) {
            if (localStorage.getItem('versi') == null) {
              localStorage.setItem('versi', response.data.versi)
            } else {
              if (localStorage.getItem('versi') != response.data.versi) {
                localStorage.setItem('versi', response.data.versi)
                location.reload();
              }
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            // AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    //tampilan
    hoverItemNavbar(param) {
      this.$refs[param].classList.add("bg-blue-custom");
    },
    leaveItemNavbar(param) {
      if (this.$refs[param].classList.contains("bg-blue-custom")) {
        this.$refs[param].classList.remove("bg-blue-custom");
      }
    },
    toggleNavbar() {
      this.isHovered = false;
      if (this.isShowNavbar == false) {
        this.isShowNavbar = true;
        this.$emit("isShowNavbar", true);
        localStorage.setItem("isShowNavbar", true);
        return true;
      }
      this.isShowNavbar = false;
      this.$emit("isShowNavbar", false);
      localStorage.setItem("isShowNavbar", false);
    },
    saveScrollPosition() {
      const scrollPosition = this.$refs.scrollableDiv.scrollTop;
      localStorage.setItem("scrollPosition", scrollPosition);
    },
    restoreScrollPosition() {
      const scrollPosition = localStorage.getItem("scrollPosition");
      this.$nextTick(() => {
        this.$refs.scrollableDiv.scrollTop = scrollPosition;
      });
    },
  },

  beforeMount() {
    this.restoreScrollPosition();
  },

  created() {
    this.check_version();
  },
  mounted() {
    //data
    this.token = Cookies.get("token");
    this.config = {
      headers: { Authorization: `Bearer ` + this.token },
      timeout: 0,
    };
    this.index();
    if (this.tipe == "pegawai") {
      this.index_notifikasi();
    }

    //tampilan
    this.isShowNavbar = JSON.parse(localStorage.getItem("isShowNavbar"));
    this.$emit(
      "isShowNavbar",
      JSON.parse(localStorage.getItem("isShowNavbar"))
    );
  },
};
</script>
